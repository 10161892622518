<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Fees" subtitle="See all the fees incurred through badges.">

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no fees found.">

		<template v-slot:persist>
				
			<div class="summary-wrapper">
				
				<app-content-summary title="Collected" :grow="true" icon="payouts">

					<app-content-summary-item :grow="true" label="Badge fees" :value="$convention.count.billing.badges_total | formatMoney('+')" />
					<app-content-summary-item :grow="true" label="Sales fees" :value="$convention.count.billing.cut_total | formatMoney('+')" />
					<app-content-summary-item :grow="true" label="Total fees" :value="$convention.count.billing.platform_total | formatMoney('=')" />

				</app-content-summary>
				
				<app-content-summary title="Rates" :grow="true" icon="billing">

					<app-content-summary-item :grow="true" :label="($organisation.currency === 1) ? 'Badge Fee' : 'Badge Fee (USD)'" :value="$convention.billing.fee | formatMoney" />
					<app-content-summary-item :grow="true" label="Sale Fee" :value="($organisation.currency === 1) ? '4.0% / Min. $' + $platform.billing.charge.min : '4.0% / Min. $' + $platform.billing.charge.min + ' (USD)'" />
					<app-content-summary-item :grow="true" v-if="$organisation.currency === 2" label="International Fee" value="+1.5%" />

				</app-content-summary>

			</div>

		</template>

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Date" />
				<app-table-column text="Sale No." />
				<app-table-column text="Badge No." />
				<app-table-column text="Type" />
				<app-table-column text="Fee" />

			</app-table-head>

			<app-table-body>

				<app-table-row :no-click="true" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY h:mma')" />
					<app-table-cell-text :can-empty="true" :text="item.sale ? item.sale.reference : false" />
					<app-table-cell-text :can-empty="true" :text="item.badge ? item.badge.reference : false" />
					<app-table-cell-text :text="$constants.billing.charge.type[item.type]" />
					<app-table-cell-text :text="item.amount | formatMoney" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '200px 120px 120px auto 120px',
			endpoint: 'convention/billing/fees',
			live: 'convention/billing/fees',
			edit: 'Convention.Billing.Fees.Edit'
		}

	}

}

</script>

<style scoped>

.summary-wrapper {
	display: flex;
}

</style>